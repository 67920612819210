/** @jsx jsx */
import { jsx, Themed } from "theme-ui"
import { LineChart, Line, XAxis, YAxis, Tooltip } from "recharts"

import Layout from "../components/layout"
import SEO from "../components/seo"
import dates from "../util/date"

const DATA = {
  movements: [
    {
      name: "Incline Press",
      prs: [
        {
          date: "2020-07-28T00:00:00-04:00",
          weight: 225,
          unit: "lb",
        },
        {
          date: "2020-10-06T00:00:00-04:00",
          weight: 235,
          unit: "lb",
          bodyweight: 175,
        },
        {
          date: "2020-12-22T00:00:00-04:00",
          weight: 245,
          unit: "lb",
          bodyweight: 175,
        },
      ],
    },
    {
      name: "Bench Press",
      prs: [
        {
          date: "2016-08-04T00:00:00-04:00",
          weight: 255,
          unit: "lb",
        },
        {
          date: "2017-03-24T00:00:00-04:00",
          weight: 260,
          unit: "lb",
        },
        {
          date: "2020-08-28T00:00:00-04:00",
          weight: 265,
          unit: "lb",
        },
        {
          date: "2020-09-10T00:00:00-04:00",
          weight: 270,
          unit: "lb",
        },
        {
          date: "2020-10-06T00:00:00-04:00",
          weight: 275,
          unit: "lb",
        },
        {
          // also did 280 paused for a 15lb PR (prev best 265 paused)
          date: "2020-11-06T00:00:00-05:00",
          weight: 290,
          unit: "lb",
          bodyweight: 175,
        },
        {
          // also did 285 paused for a 5lb PR
          date: "2020-12-24T00:00:00-05:00",
          weight: 295,
          unit: "lb",
          bodyweight: 175,
        },
      ],
    },
    {
      name: "Deadlift",
      prs: [
        {
          date: "2011-04-11T00:00:00-04:00",
          weight: 405,
          unit: "lb",
        },
        {
          date: "2012-12-14T00:00:00-05:00", // Found it on Fitocracy!
          weight: 445,
          unit: "lb",
        },
        {
          date: "2013-04-26T00:00:00-04:00",
          weight: 455,
          unit: "lb",
        },
        {
          date: "2015-11-20T00:00:00-05:00",
          weight: 475,
          unit: "lb",
        },
        {
          date: "2016-08-06T00:00:00-04:00",
          weight: 500,
          unit: "lb",
        },
        {
          date: "2016-09-10T00:00:00-04:00",
          weight: 505,
          unit: "lb",
        },
        {
          date: "2020-10-07T00:00:00-04:00",
          weight: 525,
          unit: "lb",
          bodyweight: 175,
        },
      ],
    },
    {
      name: "Back Squat",
      prs: [
        {
          date: "2016-08-02T00:00:00-04:00",
          weight: 335,
          unit: "lb",
          bodyweight: 175,
        },
      ],
    },
    {
      name: "Front Squat",
      prs: [
        {
          date: "2018-11-12T00:00:00-05:00",
          weight: 285,
          unit: "lb",
        },
        {
          date: "2020-08-28T00:00:00-04:00",
          weight: 295,
          unit: "lb",
        },
        {
          date: "2020-10-07T00:00:00-04:00",
          weight: 305,
          unit: "lb",
          bodyweight: 175,
        },
      ],
    },
    {
      name: "Snatch",
      category: "PUSH + PULL",
      prs: [
        {
          date: "2018-02-02T00:00:00-05:00",
          weight: 155,
          unit: "lb",
        },
        {
          date: "2020-09-09T00:00:00-04:00",
          weight: 175,
          unit: "lb",
          bodyweight: 172,
        },
        {
          date: "2020-12-21T00:00:00-04:00",
          weight: 176,
          unit: "lb",
          bodyweight: 175,
        },
      ],
    },
    {
      name: "Clean & Press",
      prs: [
        {
          date: "2021-03-11T00:00:00-05:00",
          weight: 185,
          unit: "lb",
          bodyweight: 177,
        },
        {
          date: "2022-02-14T00:00:00-05:00",
          weight: 195,
          unit: "lb",
          bodyweight: 178,
        },
      ],
    },
    {
      name: "Clean & Push Press",
      prs: [
        {
          date: "2020-08-30T00:00:00-04:00",
          weight: 225,
          unit: "lb",
        },
        {
          date: "2020-09-23T00:00:00-04:00",
          weight: 235,
          unit: "lb",
        },
        {
          date: "2020-10-25T00:00:00-04:00",
          weight: 245,
          unit: "lb",
          bodyweight: 175,
        },
      ],
    },
    {
      name: "Clean & Jerk",
      prs: [
        {
          date: "2018-06-09T00:00:00-04:00",
          weight: 225,
          unit: "lb",
          bodyweight: 163,
        },
        {
          date: "2020-12-20T00:00:00-04:00",
          weight: 235,
          unit: "lb",
          bodyweight: 175,
        },
        {
          date: "2021-02-05T00:00:00-04:00",
          weight: 245,
          unit: "lb",
          bodyweight: 175,
        },
      ],
    },
  ],
}

const lbToKg = lb => Math.round(lb / 2.204623)

const TrainingPage = () => {
  return (
    <Layout>
      <SEO title="Training" />
      <Themed.h1 sx={{ mb: 4 }}>Training</Themed.h1>
      <table
        sx={{
          "th, td": {
            py: 2,
            pr: 4,
          },
          td: {
            borderBottom: "1px solid #eee",
          },
        }}
      >
        <thead>
          <tr>
            <th> </th>
            <th>Personal Best</th>
            <th>Strength/BW</th>
            <th>Last Update</th>
            <th>Activity</th>
          </tr>
        </thead>
        <tbody>
          {DATA.movements.map(m => {
            const best = m.prs[m.prs.length - 1]
            return (
              <tr>
                <td sx={{ fontWeight: 500 }}>{m.name}</td>
                <td>
                  {best.weight} {best.unit} ({lbToKg(best.weight)} kg)
                </td>
                <td>
                  {best.bodyweight
                    ? (best.weight / best.bodyweight).toFixed(2)
                    : "unknown"}
                </td>
                <td>{dates.format(best.date)}</td>
                <td>
                  <LineChart
                    width={100}
                    height={48}
                    data={m.prs.map(pr => ({
                      ...pr,
                      date: dates.format(pr.date),
                    }))}
                    // margin={{ top: 24, bottom: 24 }}
                  >
                    <XAxis dataKey="date" hide={true} />
                    <YAxis
                      type="number"
                      domain={["dataMin - 20", "dataMax + 20"]}
                      hide={true}
                    />
                    <Tooltip />
                    <Line
                      type="monotone"
                      dataKey="weight"
                      stroke="#817df2"
                      activeDot={{ r: 8 }}
                    />
                  </LineChart>
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </Layout>
  )
}

export default TrainingPage
